
// import React, { Component ,Alert } from 'react'
// import {
//     BrowserRouter as Router,
//     Switch,
//     Route,
//     Link,
//     Routes
//   } from "react-router-dom";
//   import axios from 'axios';

// export default class Test extends Component {
//   componentDidMount() {
//     const head = document.querySelector("head");
//     let script = document.createElement("script");
//     script.setAttribute(
//       "src",
//       process.env.PUBLIC_URL+"/webcam.min.js"
//     );
//     head.appendChild(script);

//     script = document.createElement("script");
//     script.setAttribute(
//       "src",
//       process.env.PUBLIC_URL+"/qcode-decoder.min.js"
//     );
//     head.appendChild(script);
 
//   }

//     constructor(props) {
//         super(props);
//         this.handleChangeUsername = this.handleChangeUsername.bind(this);
//         this.handleChangePassword = this.handleChangePassword.bind(this);
//         this.apiCheck = this.apiCheck.bind(this)
    
//         this.state = {
//             persons: '',
//             username:'',
//             password:'',
//             Change:'',
//             loading:false,
//              TestProps:'ff'};
//       }


//       apiCheck(){
//         let apikey = ''
//         axios({
//           method: 'post',
//           url: "https://cashierapi.cdncomm.com/api/cashierAuth",
//           headers: {"Access-Control-Allow-Origin": "localhost:3000" ,  "Content-Type": "application/json"}, 
//           data: {
//             UserName: this.state.username, 
//             Password :  this.state.password
//           }})
//           .then(res => {
//             apikey=res.data;
//             console.log(apikey)
//             axios({
//               method: 'post',
//               url: `https://cashierapi.cdncomm.com/api/mget/logon?username=${this.state.username}&Password=${this.state.password}`,
//               headers: {
//                       'Authorization': "Bearer " + apikey,
//                       "Access-Control-Allow-Origin": "localhost:3000" ,
//                        "Content-Type": "application/json" },
//                   }).then(res =>
//                       console.log(res.data),
//                       console.log(this.state.persons)
//                   );
//                     this.setState({persons:res.data})
//                  })
//       }


//      handleChangeUsername = event => {
//         this.setState({username:event.target.value});
//       };
//        handleChangePassword = event => {
//         this.setState({password:event.target.value});
//       };
//       handleUploadImage() {
//         alert("Kullanıcı kayıtlı değil")
//     }
//   render() {
//     return (
//         <div className="container">
//     <div className='headerArea'>
//       <p className='headerText'>Cashier</p>
//     </div>
//     <div className="ImageArea">
//         <img src='./Cashier.png' width={160} height={160}></img>
//     </div>
//     <div className='loginArea'>
//       <div className='usernameArea'>
//         <div className='LoginText'>Username</div>
//         <input onChange={this.handleChangeUsername}  type="text" name="username"   required="required" />
//       </div>
//       <div className='PasswordArea'>
//         <div className='LoginText'>Password</div>
//         <input  onChange={this.handleChangePassword} type="text" name="Password" required="required" />
//       </div>
//        <div  className='buttonArea'>
//            {/* <Link onClick={this.apiCheck} className='button'  to={`/Home/${this.state.username}/${this.state.password}/${this.state.persons}`}>Login</Link> */}
//            <Link onClick={this.apiCheck} className='button'  to={`/FindUser`}>Login</Link>

//        </div>
//     </div>
//  </div>
//     )
//   }
// }