import logo from './logo.svg';
import './App.css';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Routes
} from "react-router-dom";

import React, { useState , useEffect } from 'react';
import axios from 'axios';
import Register from './Register';
import Home from './Home';
import Credits from './Credits';
import Voucher from './Voucher';
import TestHome from './TestHome';
import Profile from './Profile';
import LastVoucher from './LastVoucher';
import CreditIn from './CreditIn';
import CashOut from './CashOut';
import FindUser from './FindUser';
import BarcodeDetails from './BarcodeDetails';
import BarcodeCashDetails from './BarcodeCashDetails';
import { Context } from './Context';

function App() {

  return (
    <div >
    <Router>
      <Context>
      <div>
      <Routes>
      <Route exact  path='/'   element={<Home  />} />
      <Route  path='*'   element={<Home/>} />
      <Route  path="/Home"   element={<Credits/>} />
      <Route  path="/NewVoucher"   element={<Voucher/>} />
      <Route  path="/CreditIn"   element={<CreditIn/>} />
      <Route  path="/CashOut"   element={<CashOut/>} />
      <Route  path="/LastVoucher"   element={<LastVoucher/>} />
      <Route  path="/Profile"   element={<Profile/>} />
      <Route  path="/FindUser"   element={<FindUser/>} />
      <Route  path="/BarcodeDetails"   element={<BarcodeDetails/>} />
      <Route  path="/BarcodeCashDetails"   element={<BarcodeCashDetails/>} />
      </Routes>
      </div>
      </Context>
    </Router>
  </div>
  );
}

export default App;
