
import React, { useState ,useEffect , useContext } from 'react';
import {Link, Routes, Route, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useLocation } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import  Context  from './Context';
export default function BarcodeDetails() {
    const navigate = useNavigate();
    const location = useLocation();
    const [BarcodeDetails, setBarcodeDetails] = useState("");
    const [InfoPageDetails, setInfoPageDetails] = useState(false);
    const [DetailsData, setDetailsData] = useState("");
    const [NewCredit ,setCreditNew] = useState("")
    const {Api} = useContext(Context)


    window.didchange = function(uid ){
      if(location.state.userId == uid) {
       axios({
              method: 'post',
              url: `${Api}/api/mget/logon?username=${location.state.Username}&Password=${location.state.Password}`,
              headers: {
                     'Authorization': "Bearer " + location.state.Tokens,
                     "Access-Control-Allow-Origin": "localhost:3000" ,
                      "Content-Type": "application/json" },
            }).then(res => {
              if(res.data.activ == "1" && res.data.userExist == "1") {
                 setCreditNew(res.data.credit)
              }
              else if(res.data.activ == "0"){
                       navigate('/')
              }
            }
            )
      }
    }

    const BarcodeDetailsFunc=(event)=>{
      BarcodeDetails ==0 ?
        setBarcodeDetails(event.currentTarget.id)
        :
        setBarcodeDetails(BarcodeDetails+event.currentTarget.id)
       }
       function deleteBarcodeCount() {
        setBarcodeDetails("")
       }
       const BarcodeDetailsInput = event => {
        setBarcodeDetails(event.target.value);
     };

     function TurnHomeDetails() {
       navigate('/Home',{state:{  credit:DetailsData, token:location.state.Tokens , from:DetailsData , UserNameInfo:location.state.Username , Password:location.state.Password , userId:location.state.userId }})
     }


     function CheckCredit(){
      BarcodeDetails> 0 && (NewCredit==""?location.state.credit:NewCredit) >=  parseInt(BarcodeDetails) ?
            axios({
              method: 'patch',
              url: `${Api}/api/mget/deposit`,
              headers: {
                      'Authorization': "Bearer " + location.state.Tokens,
                      "Access-Control-Allow-Origin": "localhost:3000" ,
                       "Content-Type": "application/json" },
                       data: {
                        UserId: location.state.BarcodeDetails.user_Id, 
                        Credit : BarcodeDetails
                     }
            }).then(res => {
              setInfoPageDetails(true)
              setDetailsData(res.data)
            }
            ).catch(err => err.response.status === 401 ? navigate('/') : err.response.status === 400 ? toast.warning("The process is currently unavailable.") : "")
            :
            BarcodeDetails === ""?
            toast.warning("Credit cannot be empty")
            :
            BarcodeDetails == 0  ?
            toast.warning("Credit must be bigger than 0")
            :
            (NewCredit==""?location.state.credit:NewCredit) <  parseInt(BarcodeDetails)?
            toast.warning(`You don't have ${BarcodeDetails} credits`)
            :
            console.log("")
     }
  return (
    <div className="CreditInContainer">
 {
              InfoPageDetails ? 
              <div>
              <div className='headerArea'>
                 <div className='headerText'><div className='HeaderImageTextArea'>Cashier</div></div>
               </div>
               <div className='SuccessArea'>
                      <div className='SuccesText'>Success</div>
                      <div className='CreditText'>Credit :{location.state.BarcodeDetails.credit + parseInt(BarcodeDetails)}  </div>
                      <button onClick={TurnHomeDetails} className='buttonLightBlueSuccessCredit' >OK</button>
               </div>
             </div>
             :
             <div>
    <div className='headerArea'>
        <div className='headerText'><div className='HeaderImageTextArea'><button className='ImageButton' onClick={() => (setCreditNew(""), navigate('/CreditIn',{state:{  credit:(NewCredit == "" ? location.state.credit :NewCredit), tokendata:location.state.Tokens , from:DetailsData , Username:location.state.Username , Password:location.state.Password , userId:location.state.userId }}))}><img width={25} height={20} className='imgStyleBack' src='./BackLogo.png'></img></button>Check Before</div><img  width={31} height={31} className='imgStyleBack' src='./Threedot.png'></img></div>
    </div>
    <div className='container'>
        <div className='BarcodeTextArea'>
             <div className='BarcodeDetailsText'><span className='BarcodeTextHeader'>Username :</span>  {location.state.BarcodeDetails.user_Name}</div>
             <div className='BarcodeDetailsText'><span className='BarcodeTextHeader'>Credit :</span> {location.state.BarcodeDetails.credit}</div>
        </div>
          <div className='CreditAmountArea'>
            <div className='AmountText'>Credits Amount:</div>
            <div className='InputEffect' type="text" value={BarcodeDetails}  name="amount" onChange={BarcodeDetailsInput}  required="required">{BarcodeDetails}</div>
          </div>
          <div  className='buttonAreaVoucher'>
               <button onClick={CheckCredit} className='buttonLightBlueCredit' >VOUCHER CREDITS IN</button>
               <ToastContainer />
            </div>
          <div className='CreditNumberArea'>
          <div className='RowArea'>
              <button id='1' onClick={BarcodeDetailsFunc}>1</button>
              <button id='2' onClick={BarcodeDetailsFunc}>2</button>
              <button id='3' onClick={BarcodeDetailsFunc}>3</button>
            </div>
            <div className='RowArea'>
              <button id='4' onClick={BarcodeDetailsFunc}>4</button>
              <button id='5' onClick={BarcodeDetailsFunc}>5</button>
              <button id='6' onClick={BarcodeDetailsFunc}>6</button>
            </div>
            <div className='RowArea'>
              <button id='7' onClick={BarcodeDetailsFunc}>7</button>
              <button id='8' onClick={BarcodeDetailsFunc}>8</button>
              <button id='9' onClick={BarcodeDetailsFunc}>9</button>
            </div>
            <div className='RowArea'>
              <button id='00' onClick={BarcodeDetailsFunc}>00</button>
              <button id='0' onClick={BarcodeDetailsFunc}>0</button>
              <button onClick={deleteBarcodeCount} >DEL</button>
            </div>
          </div>
    </div>
  </div>
 }
    </div>
  )
}
