import React from 'react'


export default class FindUser extends React.Component {
    constructor(props) {
        super(props);
        this.state = {resultval: "", codes:"" };

      }
    componentDidMount() {
      
      window.Webcam.set({
        width: 200,
        height: 200,
        dest_width: 200, // device capture size
      dest_height: 200,
      crop_width: 200, // final cropped size
      crop_height: 200,
		image_format: 'jpeg',
		jpeg_quality: 90
		});
		window.Webcam.attach("#my_camera");

      }
      render() {
 
 
 
        const dataYazdir=()=>{
            alert("sadas");
         javascript:window.lee.PrintCode("huseyin!bc123!fjretmveodx!betbanca.com");   
        };
     
     
        let CamInterval;

        const readQr=()=>{
    try {
    var img =  document.getElementById("staticimage");
    var canvas = document.createElement('canvas');
    canvas.width = img.width;
    canvas.height = img.height;
    var ctx = canvas.getContext('2d');
    // rotate by 90 deg
    ctx.rotate(Math.PI);
    ctx.translate(-img.width, -img.height);
    ctx.drawImage(img, 0, 0);
    // decode
    

    alert(canvas.toDataURL());
      window.QCodeDecoder().decodeFromImage(canvas.toDataURL(), function(err, res){
      document.body.appendChild(document.createTextNode(res||err))
      alert(res);
      });
     } catch (error) {
         alert(error);
     }

    }
        const reaccamqr=()=>{
            try {
            var img =  document.getElementById("asd");
            var canvas = document.createElement('canvas');
            canvas.width = img.width;
            canvas.height = img.height;
            var ctx = canvas.getContext('2d');
            // rotate by 90 deg
            ctx.rotate(Math.PI);
            ctx.translate(-img.width, -img.height);
            ctx.drawImage(img, 0, 0);
            // decode
            
        
            alert(canvas.toDataURL());
              window.QCodeDecoder().decodeFromImage(canvas.toDataURL(), function(err, res){
              document.body.appendChild(document.createTextNode(res||err))
              alert(res);
              });
             } catch (error) {
                 alert(error);
             }
        
        
        }
    

    const TakeQr=()=>{
    try {


        var img =  document.getElementById("das");
        var canvas = document.createElement('canvas');
        canvas.width = img.width;
        canvas.height = img.height;
        var ctx = canvas.getContext('2d');
        // rotate by 90 deg
        ctx.rotate(Math.PI);
        ctx.translate(-img.width, -img.height);
        ctx.drawImage(img, 0, 0);
          window.QCodeDecoder().decodeFromImage(canvas.toDataURL(), function(err, res){
        document.body.appendChild(document.createTextNode(res||err))

          if(res===undefined){

          }
          else{
            alert(res);

          }
          });
         } catch (error) {
             alert(error);
         }
        }

        const onsnap = (e) => {
         
          let HasSnap =false;  
          clearInterval(CamInterval);
         
            CamInterval = setInterval(() => {
                if (!HasSnap) {
                    HasSnap=true;
                 window.Webcam.snap( function(data_uri) {
              
                  document.getElementById("das").src =data_uri ;
               window.setTimeout(function(){
                try {
                    var img =  document.getElementById("das");
                    var canvas = document.createElement('canvas');
                    canvas.width = img.width;
                    canvas.height = img.height;
                    var ctx = canvas.getContext('2d');
                    // rotate by 90 deg
                    ctx.rotate(Math.PI);
                    ctx.translate(-img.width, -img.height);
                    ctx.drawImage(img, 0, 0);
                     
                    window.QCodeDecoder().decodeFromImage(canvas.toDataURL(), function(err, res){
                    //document.body.appendChild(document.createTextNode(res||err))
            
                      if(res===undefined){
            
                      }
                      else{
                        clearInterval(CamInterval);
                        document.body.appendChild(document.createTextNode(res||err))
            
                      }
                      });
                     } catch (error) {
                         alert(error);
                     }

                
               },200)

                     HasSnap=false;
                   
                    });
                }
            }, 500);

         
        

        }
        return (
            <div> 
               <div >  

               Sayfa açıldı</div>
               <br></br>
            <div className='' onClick={onsnap} onTouchStart={onsnap} id="my_camera"></div>
         
            <br /><br /><br />
            <button className='Btnokut' onClick={dataYazdir} >Qr Çıkar</button>
            <br /><br /><br /><br /><br />
            <button className='Btnokut' onClick={TakeQr} >Qr Okut</button>

            <button className='Btnokut' onClick={TakeQr} >Qr Okut</button>

            </div>

            );
      }
}


