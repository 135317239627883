import React, { useState ,useEffect , useContext  } from 'react';
import {Link, Routes, Route, useNavigate, useLocation} from 'react-router-dom';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import  Context  from './Context';

export default function Profile() {
  const navigate = useNavigate();
  const location = useLocation();
    const [dotProfile, setDotProfile] = useState(false);
    const [ProfileData ,  setProfileData] = useState("")
    const [NewCredit , setCreditNew] = useState("")
    const {Api} = useContext(Context)


    window.didchange = function(uid ){
      if(location.state.userId == uid) {
       axios({
              method: 'post',
              url: `${Api}/api/mget/logon?username=${location.state.Username}&Password=${location.state.Password}`,
              headers: {
                     'Authorization': "Bearer " + location.state.tokendata,
                     "Access-Control-Allow-Origin": "localhost:3000" ,
                      "Content-Type": "application/json" },
            }).then(res => {
              if(res.data.activ == "1" && res.data.userExist == "1") {
                 setCreditNew(res.data.credit)
              }
              else if(res.data.activ == "0"){
                       navigate('/')
              }
            }
            )
      }
    }
    function threeDotProfile(){
        setDotProfile(!dotProfile)
      }


      useEffect(() => { 
        axios({
          method: 'get',
          url: `${Api}/api/mget/GetShopAcc`,
          headers: {
                  'Authorization': "Bearer " + location.state.tokendata,
                   "Content-Type": "application/json" },
        }).then(res => {
          setProfileData(res.data)
        }
        ).catch(err => err.response.status === 401 ? navigate('/') : err.response.status === 400 ? toast.warning("The process is currently unavailable.") : "")
      },[])

  return (
    <div className="container">
    <div className='headerArea'>
    <div className='headerText'><div className='HeaderImageTextArea'><button className='ImageButton' onClick={() => (setCreditNew("") , navigate('/Home',{state:{ credit:(NewCredit == "" ? location.state.credit : NewCredit) ,token:location.state.tokendata  , UserNameInfo:location.state.Username ,userId :location.state.userId , Password:location.state.Password}}))}><img width={25} height={20} className='imgStyleBack' src='./BackLogo.png'></img></button><span className='TextHeaderStyle'>Profile</span></div><img onClick={threeDotProfile} width={31} height={31} className='imgStyleBack' src='./Threedot.png'></img></div>
    </div>
    {
       dotProfile?
       <div className='modalLogin'>
         <div className='UsernameAreaModal'>{location.state.Username}</div>
         <Link to={'/'} className='logoutButtonModal'>LogOut</Link>
       </div>
       :
       ""
     }
    <div className="ImageArea">
        <img src='./Cashier.png' width={160} height={160}></img>
    </div>
    <div className='loginArea'>
      <div className='ProfileLine'>
        <div>Shop Username :</div>
        <div>{location.state.Username}</div>
      </div>
      <div className='ProfileLine'>
        <div>Cashier Username :</div>
        <div>{location.state.Username}</div>
      </div>
      <div className='ProfileLine'>
        <div>IN :</div>
        <div>{ProfileData.stake}</div>
      </div>
      <div className='ProfileLine'>
        <div>OUT :</div>
        <div>{ProfileData.winning}</div>
      </div>
      <div className='ProfileLine'>
        <div>Total :</div>
        <div>{ProfileData.total}</div>
      </div>
      <div className='ProfileLine'>
        <div>Credits :</div>
        <div>{location.state.credit}</div>
      </div>

    </div>
 </div>
  )
}
