import React, { useState , useEffect , useContext } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { useLocation } from 'react-router-dom'
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import  Context from './Context';



export default function Home() {
 const navigate = useNavigate();
   const [persons, setPersons] = useState("");
   const [dataPer, setDataPer] = useState("");
   const [username, setUsername] = useState("");
   const [Password, setPassword] = useState("");
   const [loading, setloading] = useState(false);
   const {Api} = useContext(Context)
   


    function LoginAction(){
      username !==""  && Password !=="" ?
      axios({
        method: 'post',
        url: `${Api}/api/cashierAuth` ,
        headers: {"Access-Control-Allow-Origin": "localhost:3000" ,  "Content-Type": "application/json"}, 
        data: {
          UserName: username, 
          Password : Password
       }})
        .then(res => {
           setPersons(res.data) 
          axios({
            method: 'post',
            url: `${Api}/api/mget/logon?username=${username}&Password=${Password}`,
            headers: {
                    'Authorization': "Bearer " + res.data,
                    "Access-Control-Allow-Origin": "localhost:3000" ,
                     "Content-Type": "application/json" },
                }).then(logon => {
                  setDataPer(logon.data)
              
             if ( logon.data.userExist == "1" && logon.data.activ == "1") {


              navigate('/Home',{state:{from:logon.data , token:res.data , credit:logon.data.credit , UserNameInfo:logon.data.userName , Password:Password , userId:logon.data.userId}})
              
              //window.sck.server.userLogin(logon.data.userId);

              setloading(true)



             } else if(logon.data.activ =="0") {
              toast.error("Your Account is not active")
             } 

                }
                );
              }).catch(err => err.response.status === 401 ? toast.error("The Username or Password is incorrect") : err.response.status === 400 ? toast.warning("The process is currently unavailable.") : "")
              :
              username ==="" ?
              toast.error("Username cannot be empty")
              :
              Password ==="" ?
              toast.error("Password cannot be empty")
              :
              console.log("")
    }
   

    // window.didchange = function(){
    //    console.log("didchange");
    // }

   
   useEffect(()=>  {
    const head = document.querySelector("head");
    let script = document.createElement("script");
    script.setAttribute(
      "src",
      process.env.PUBLIC_URL+"/webcam.min.js"
    );

    head.appendChild(script);

    script = document.createElement("script");
    script.setAttribute(
      "src",
      process.env.PUBLIC_URL+"/qcode-decoder.min.js"
    );
    head.appendChild(script);


    // script = document.createElement("script");
    // script.setAttribute(
    //   "src", process.env.PUBLIC_URL+"/jquery-2.1.4.min.js"
    // );
    // script.async=false;
    // script.setAttribute(
    //   "defer", ""  );
    // head.appendChild(script);


    // script = document.createElement("script");
    // script.setAttribute(
    //   "src",
    //   process.env.PUBLIC_URL+"/jquery.signalR-2.0.1.min.js"
    // );
    // script.async=false;
    // script.setAttribute(  "defer", "" );
    // head.appendChild(script);

    // script = document.createElement("script");
    // script.setAttribute(
    //   "src",
    //   "http://localhost:59719/signalr/hubs"
    // );
    // script.async=false;
    // script.setAttribute(
    //   "defer", ""  );
    // head.appendChild(script);

    // script = document.createElement("script");
    // script.setAttribute(
    //   "src",
    //   process.env.PUBLIC_URL+"/socket.js")

    // script.async=false;
    // script.setAttribute(
    //   "defer", ""  );
    // head.appendChild(script);



   }
   )


   const onChangeHandler = event => {
    setUsername(event.target.value);
 };

 const onChangeHandlerpASS = event => {
  setPassword(event.target.value);
};

  return (
    <div className="container">
    <div className='headerArea'>
      <p className='headerText'>Cashier</p>
    </div>
    <div className="ImageArea">
        <img src='./Cashier.png' width={160} height={160}></img>
    </div>
    <div className='loginAreaHome'>
      <div className='usernameArea'>
        <div className='LoginText'>Username</div>
        <input  onChange={onChangeHandler} type="text" name="username"   required="required" />
      </div>
      <div className='PasswordArea'>
        <div className='LoginText'>Password</div>
        <input  onChange={onChangeHandlerpASS} type="password" name="Password" required="required" />
      </div>
       <div  className='buttonArea'>
         <button onClick={LoginAction} className='button' > Login</button>
         <ToastContainer />
       </div>
    </div>
 </div>
  )
}
