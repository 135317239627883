import { createContext, useState } from "react";


const ContextData = createContext();

export const  Context = ({children}) => {
    const [Api,setApi] = useState("https://cashierapi-aws.nvpark.com")

    const value = {
        Api
      };
  return <ContextData.Provider value={value}>{children}</ContextData.Provider>

}
export default  ContextData;