
import React, { useEffect, useState  } from 'react';

import axios from 'axios';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Routes,
  useParams
} from "react-router-dom";

export default function TestHome() {
  const { id } = useParams();

  const [isStart, setIsStart] = useState(false);
  const [token, setToken] = useState("");
  const [loading, setLoading] = useState(false);
  const [persons, setPersons] = useState("");
  const [dataPer, setDataPer] = useState("");
  const [count, setCount] = useState(1);
  

  // useEffect(() => {
  //   axios({
  //          method: 'post',
  //        url: "https://cashierapi.cdncomm.com/api/cashierAuth",
  //          headers: {"Access-Control-Allow-Origin": "localhost:3000" ,  "Content-Type": "application/json"}, 
  //          data: {
  //            UserName: "user1", 
  //            Password :  "Cs862601*"
  //         }})
  //          .then(res => {
  //          setPersons(res.data)
  //            axios({
  //            method: 'post',
  //            url: `https://cashierapi.cdncomm.com/api/mget/logon?username=user1&Password=Cs862601*`,
  //              headers: {
  //                    'Authorization': "Bearer " + persons,
  //                      "Access-Control-Allow-Origin": "localhost:3000" ,
  //                       "Content-Type": "application/json" },
  //                  }).then(res =>
  //                   setDataPer(res.data),
  //                   console.log(dataPer)
  //                  );
  //                })
  // }, [dataPer]);

  // const handleClick = () => {
  //   setCount(count + 1);
  // };

  
    // useEffect(() => {
    //   axios({
    //     method: 'post',
    //     url: "https://cashierapi.cdncomm.com/api/cashierAuth",
    //     headers: {"Access-Control-Allow-Origin": "localhost:3000" ,  "Content-Type": "application/json"}, 
    //     data: {
    //       UserName: "user1", 
    //       Password :  "Cs862601*"
    //     }})
    //     .then(res => {
    //       setPersons(res.data)
    //       axios({
    //         method: 'post',
    //         url: `https://cashierapi.cdncomm.com/api/mget/logon?username=user1&Password=Cs862601*`,
    //         headers: {
    //                 'Authorization': "Bearer " + persons,
    //                 "Access-Control-Allow-Origin": "localhost:3000" ,
    //                  "Content-Type": "application/json" },
    //             }).then(res =>
    //               setDataPer(res.data),
    //               console.log(dataPer)
    //             );
    //            })
      
    // });



  return (
    <div>


    <button >
      add
    </button>
  </div>
  )
}
