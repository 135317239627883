
import React, { useState , useEffect , useContext } from 'react';
import {Link, Routes, Route, useNavigate ,useLocation} from 'react-router-dom';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { act } from 'react-dom/test-utils';
import  Context  from './Context';

export default function CreditIn() {
  const navigate = useNavigate();
  const location = useLocation();
    const [hash, setHash] = useState(true);
    const [Voucher, setVoucher] = useState(false);
    const [Barcode, setBarcode] = useState(false);
    const [SelectData, setSelectData] = useState("");
    const [dotCreditIn, setDotCreditIn] = useState(false);
    const [SelectValue, setSelectValue] = useState("");
    const [SelectNumber, setSelectNumber] = useState("");
    const [CreditInRefresh, setCreditInRefresh] = useState("");
    const [InfoPage, setInfoPage] = useState(false);
    const [DataObject, setDataObject] = useState("");
    const [CreditNew, setCreditNew] = useState("");
    const {Api} = useContext(Context)
  


    window.didchange = function(uid ){
      if(location.state.userId == uid) {
       axios({
              method: 'post',
              url: `${Api}/api/mget/logon?username=${location.state.Username}&Password=${location.state.Password}`,
              headers: {
                     'Authorization': "Bearer " + location.state.tokendata,
                     "Access-Control-Allow-Origin": "localhost:3000" ,
                      "Content-Type": "application/json" },
            }).then(res => {
              if(res.data.activ == "1" && res.data.userExist == "1") {
                 setCreditNew(res.data.credit)
              }
              else if(res.data.activ == "0"){
                       navigate('/')
              }
            }
            )
      }
    }

    function threeDotCredit(){
        setDotCreditIn(!dotCreditIn)
    }

    function hashTest(){
        setHash(true)
        setVoucher(false)
        setSelectValue("")

    }

    function VoucherTest(){
        setVoucher(true)
        setHash(false)
    }

    const onChangeHandlerVoucher = event => {
      setBarcode(event.target.value);
   };

   const handleChange = event => {
    setSelectValue(event.target.value);
    setCreditInRefresh(event.target.value);
  }

  const SelectNumberFunc=(event)=>{
    SelectNumber == 0 ?
    setSelectNumber(event.currentTarget.id)
    :
    setSelectNumber(SelectNumber+event.currentTarget.id)
   }
   
   function deleteSelectNumberFunc() {
    setSelectNumber("")
   }
   const SelectNumberInput = event => {
    setSelectNumber(event.target.value);
 };


  function getUsers(){
    setVoucher(true)
    setHash(false)
    axios({
      method: 'get',
      url: `${Api}/api/mget/GetUsers`,
      headers: {
              'Authorization': "Bearer " + location.state.tokendata,
              "Access-Control-Allow-Origin": "localhost:3000" ,
               "Content-Type": "application/json" },
    }).then(res => {
      setSelectData(res.data)
    }

    ).catch(err => err.response.status === 401 ? navigate('/') : err.response.status === 400 ? toast.warning("The process is currently unavailable.") : "")
  }

  function TurnHome(){
    navigate('/Home',{state:{ credit:DataObject ,token:location.state.tokendata , from:DataObject , UserNameInfo:location.state.Username , Password:location.state.Password , userId:location.state.userId}})
  }

  function CreditUpdate(){
    SelectNumber > 0 && SelectValue !== "" &&  (CreditNew =="" ?location.state.credit : CreditNew) >= parseInt(SelectNumber) ?
      axios({
        method: 'patch',
        url: `${Api}/api/mget/deposit`,
        headers: {
                'Authorization': "Bearer " + location.state.tokendata,
                "Access-Control-Allow-Origin": "localhost:3000" ,
                 "Content-Type": "application/json" },
                 data: {
                  UserId: SelectValue.split(":")[1], 
                  Credit : SelectNumber
               }
      }).then(res => {
        setInfoPage(true)
        setDataObject(res.data)
      }
      ).catch(err => err.response.status === 401 ? navigate('/') : err.response.status === 400 ? toast.warning("The process is currently unavailable.") : "")
      :
      SelectValue === "" ?
      toast.warning("Select Voucher")
      :
      SelectNumber  ==="" ?
      toast.warning("Credit cannot be empty")
      :
      SelectNumber == 0  ?
      toast.warning("Credit must be bigger than 0")
      :
      (CreditNew =="" ?location.state.credit : CreditNew) < parseInt(SelectNumber) ?
      toast.warning(`You don't have ${SelectNumber} credits`)
      :
      console.log("")
  }

  let CamInterval;

  const onsnap = (e) => {
           
    let HasSnap =false;  
    clearInterval(CamInterval);
   
      CamInterval = setInterval(() => {
          if (!HasSnap) {
              HasSnap=true;
           window.Webcam.snap( function(data_uri) {
        
            document.getElementById("das").src =data_uri ;
          window.setTimeout(function(){
          try {
              var img =  document.getElementById("das");
              var canvas = document.createElement('canvas');
              canvas.width = img.width;
              canvas.height = img.height;
              var ctx = canvas.getContext('2d');
              // rotate by 90 deg
              ctx.rotate(Math.PI);
              ctx.translate(-img.width, -img.height);
              ctx.drawImage(img, 0, 0);
               
              window.QCodeDecoder().decodeFromImage(canvas.toDataURL(), function(err, res){
              //document.body.appendChild(document.createTextNode(res||err))
      
                if(res===undefined){
      
                }
                else{
                  clearInterval(CamInterval);
  
                  let barcode = res.split('=');
                 
                    if(barcode[1].length>13)
                    {
                    setBarcode(barcode[1]);
                    }
                
                //  document.body.appendChild(document.createTextNode(res||err))
      
                }
                });
               } catch (error) {
                 //  alert(error);
               }
  
          
         },200)
  
        
  
               HasSnap=false;
             
              });
          }
      }, 500);
  
    }

    useEffect(() => {

         if (hash && !Barcode) {
       
          window.Webcam.set({
            width: 200,
            height: 200,
            dest_width: 200, // device capture size
          dest_height: 200,
          crop_width: 200, // final cropped size
          crop_height: 200,
        image_format: 'jpeg',
        jpeg_quality: 90
        });
        window.Webcam.attach("#my_camera");
         }
     
      Barcode ?
      axios({
        method: 'get',
        url: `${Api}/api/mget/GetUser?barcode=${Barcode}`,
        headers: {
                'Authorization': "Bearer " + location.state.tokendata,
                 "Content-Type": "application/json" },
      }).then(res => {
        navigate('/BarcodeDetails',{state:{ Tokens:location.state.tokendata ,BarcodeDetails:res.data , credit:(CreditNew ==""?location.state.credit : CreditNew) , Username:location.state.Username, Password:location.state.Password, userId:location.state.userId}})

      }
      ).catch(err => err.response.status === 401 ? navigate('/') : err.response.status === 400 ? toast.warning("The process is currently unavailable.") : "")
      :
      console.log("")
    })
  return (
    <div className="CreditInContainer">
   {
              InfoPage ? 
              <div>
              <div className='headerArea'>
                 <div className='headerText'><div className='HeaderImageTextArea'>Cashier</div></div>
               </div>
               <div className='SuccessArea'>
                      <div className='SuccesText'>Success</div>
                      <div className='CreditText'>Credit : {parseInt(SelectValue.split(":")[2]) + parseInt(SelectNumber)} </div>
                      <button onClick={TurnHome} className='buttonLightBlueSuccessCredit' >OK</button>
               </div>
             </div>
             :
           
      

      <div>
    <div className='headerArea'>
    <div className='headerText'><div className='HeaderImageTextArea'><button className='ImageButton' onClick={() => (setCreditNew(""), navigate('/Home',{state:{ credit:CreditNew==""? location.state.credit:CreditNew ,token:location.state.tokendata , from:DataObject , UserNameInfo:location.state.Username ,Password:location.state.Password , userId:location.state.userId}}))}><img width={25} height={20} className='imgStyleBack' src='./BackLogo.png'></img></button><span className='TextHeaderStyle'>Check Before</span></div><img onClick={threeDotCredit} width={31} height={31} className='imgStyleBack' src='./Threedot.png'></img></div>
    </div>
    {
       dotCreditIn ?
       <div className='modalLogin'>
         <div className='UsernameAreaModal'>{location.state.Username}</div>
         <Link to={'/'} className='logoutButtonModal'>LogOut</Link>
       </div>
       :
       ""
     }
    <div className='AltMenu'>
       <div onClick={hashTest} className={`${hash ? "CreditAltActive" : "CreditInAlt"}`}>USE HASH</div>
       <div onClick={getUsers} className={`${hash ? "CreditInAlt" : "CreditAltActive"}`}>USE VOUCHER</div>
    </div>
    {
     hash ? 
          <div>
              <div className="ImageArea">

              <div className='cameraW'  onClick={onsnap} onTouchStart={onsnap} id="my_camera"></div>
                  <img id="das" width={200} height={200} alt="" src=""/>
              </div>
              <div className='loginArea'>
               <div className='AmountArea'>
            <div className='AmountText'>Voucher</div>
            <div className='InputEffect' type="text" value={Barcode}  onChange={onChangeHandlerVoucher}  name="amount"  required="required" >{Barcode}</div>
          </div>
       </div>
     </div>
          :
          <div className='container'>

        <div className='formStyle' > 
             <select className='SelectStyle' onChange={handleChange} >
             <option  value="">Select Voucher ...</option>
             {Object.values(SelectData).map((value, index) => {
        return (
          <option  key={index} value={value.user_Name + ":" + value.user_Id +":" +value.credit}>{value.user_Name}</option>
        );
      })}
            </select>

      </div>
         {
          SelectValue == "" ? "":
          <div className='BarcodeTextArea'>
              <div className='BarcodeDetailsText'><span className='BarcodeTextHeader'>Username :</span> {SelectValue.split(":")[0]}</div>
               <div className='BarcodeDetailsText'><span className='BarcodeTextHeader'>Credit :</span> {SelectValue.split(":")[2]}</div>
         </div>

         }
   
          <div className='CreditAmountArea'>
            <div className='AmountText'>Credits Amounts:</div>
            <div className='InputEffect' type="text" value={SelectNumber}  name="amount" onChange={SelectNumberInput}  required="required" >{SelectNumber}</div>
          </div>
          <div  className='buttonAreaVoucher'>
               <button onClick={CreditUpdate} className='buttonLightBlueCredit' >VOUCHER CREDITS IN</button>
               <ToastContainer />
            </div>
          <div className='CreditNumberArea'>
            <div className='RowAreaCredit'>
              <button id='1' onClick={SelectNumberFunc}>1</button>
              <button id='2' onClick={SelectNumberFunc}>2</button>
              <button id='3' onClick={SelectNumberFunc}>3</button>
            </div>
            <div className='RowAreaCredit'>
              <button id='4' onClick={SelectNumberFunc}>4</button>
              <button id='5' onClick={SelectNumberFunc}>5</button>
              <button id='6' onClick={SelectNumberFunc}>6</button>
            </div>
            <div className='RowAreaCredit'>
              <button id='7' onClick={SelectNumberFunc}>7</button>
              <button id='8' onClick={SelectNumberFunc}>8</button>
              <button id='9' onClick={SelectNumberFunc}>9</button>
            </div>
            <div className='RowAreaCredit'>
              <button id='00' onClick={SelectNumberFunc}>00</button>
              <button id='0' onClick={SelectNumberFunc}>0</button>
              <button onClick={deleteSelectNumberFunc} >DEL</button>
            </div>
           
          </div>
    </div>
    }
    </div>
  }
  
 </div>
  )
}
