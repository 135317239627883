import React, { useState  ,useEffect ,  useContext } from 'react';
import {Link, Routes, Route, useNavigate, useLocation} from 'react-router-dom';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import  Context  from './Context';

export default function CashOut() {
    const navigate = useNavigate();
    const location = useLocation();
    const [Cashhash, setCashHash] = useState(true);
    const [CashVoucher, setCashVoucher] = useState(false);
    const [dotCashOut, setDotCashOut] = useState(false);
    const [InputValue, setInputValue] = useState(false);
    const [SelectValueCash, setSelectValueCash] = useState("");
    const [SelectDataCash, setSelectDataCash] = useState("");
    const [SelectNumberCash, setSelectNumberCash] = useState("");
    const [SelectCashCredit, setSelectCashCredit] = useState("");
    const [InfoCash, setInfoCash] = useState(false);
    const [DataCash, setDataCash] = useState("");
    const [NewCredit, setCreditNew] = useState("");
    const {Api} = useContext(Context)


    window.didchange = function(uid ){
      if(location.state.userId == uid) {
       axios({
              method: 'post',
              url: `${Api}/api/mget/logon?username=${location.state.Username}&Password=${location.state.Password}`,
              headers: {
                     'Authorization': "Bearer " + location.state.tokendata,
                     "Access-Control-Allow-Origin": "localhost:3000" ,
                      "Content-Type": "application/json" },
            }).then(res => {
              if(res.data.activ == "1" && res.data.userExist == "1") {
                 setCreditNew(res.data.credit)
              }
              else if(res.data.activ == "0"){
                       navigate('/')
              }
            }
            )
      }
    }

    function threeDotCashOut(){
        setDotCashOut(!dotCashOut)
    }

    function hashTest(){
        setCashHash(true)
        setCashVoucher(false)
        setSelectValueCash("")
    }

    function VoucherTest(){
        setCashVoucher(true)
        setCashHash(false)
    }

    const handleChangeCash = event => {
      setSelectValueCash(event.target.value);
      setSelectCashCredit(event.target.value);
    }

    const SelectNumberInputCash = event => {
      setSelectNumberCash(event.target.value);
   };
  
    function SelectNumberFuncCashDelete(){
    setSelectNumberCash('')
   }

    const SelectNumberFuncCash=(event)=>{
      SelectNumberCash ==0 ?
      setSelectNumberCash(event.currentTarget.id)
      :
      setSelectNumberCash(SelectNumberCash+event.currentTarget.id)
     }

    const ChangeInputValue = event => {
      setInputValue(event.target.value);
   };

   function TurnHomeCash(){
       navigate('/Home',{state:{  credit:DataCash ,token:location.state.tokendata , from:DataCash, UserNameInfo:location.state.Username , Password:location.state.Password , userId:location.state.userId }})
   }



   function getUsersCash(){
    setCashVoucher(true)
    setCashHash(false)
    axios({
      method: 'get',
      url: `${Api}/api/mget/GetUsers`,
      headers: {
              'Authorization': "Bearer " + location.state.tokendata,
              "Access-Control-Allow-Origin": "localhost:3000" ,
               "Content-Type": "application/json" },
    }).then(res => {
      setSelectDataCash(res.data)
    }

    ).catch(err => err.response.status === 401 ? navigate('/') : err.response.status === 400 ? toast.warning("The process is currently unavailable.") : "")
  }


  function CreditUpdateCash(){
    SelectNumberCash > 0  && SelectValueCash!=="" &&  parseInt(SelectCashCredit.split(":")[2])  >= SelectNumberCash ?
    axios({
      method: 'patch',
      url: `${Api}/api/mget/withdraw`,
      headers: {
              'Authorization': "Bearer " + location.state.tokendata,
              "Access-Control-Allow-Origin": "localhost:3000" ,
               "Content-Type": "application/json" },
               data: {
                UserId: SelectValueCash.split(":")[1], 
                Credit : SelectNumberCash
             }
    }).then(res => {
      setInfoCash(true)
      setDataCash(res.data)
    }
    ).catch(err => err.response.status === 401 ? navigate('/') : err.response.status === 400 ? toast.warning("The process is currently unavailable.") : "")
    :
    SelectValueCash === "" ?
    toast.warning("Select Voucher")
    :
    SelectNumberCash  ==="" ?
    toast.warning("Credit cannot be empty")
    :
    SelectNumberCash == 0  ?
    toast.warning("Credit must be bigger than 0")
    :
    parseInt(SelectCashCredit.split(":")[2])  < SelectNumberCash ?
    toast.warning(`${SelectValueCash.split(":")[0]} doesn't have ${SelectNumberCash} credit`)
    :
    console.log("")
}
 let CamInterval;

const onsnap = (e) => {
  let HasSnap =false;
  clearInterval(CamInterval);
    CamInterval = setInterval(() => {
        if (!HasSnap) {
            HasSnap=true;
         window.Webcam.snap( function(data_uri) {
  
          document.getElementById("das").src =data_uri ;
        window.setTimeout(function(){
        try {
            var img =  document.getElementById("das");
            var canvas = document.createElement('canvas');
            canvas.width = img.width;
            canvas.height = img.height;
            var ctx = canvas.getContext('2d');
            // rotate by 90 deg
            ctx.rotate(Math.PI);
            ctx.translate(-img.width, -img.height);
            ctx.drawImage(img, 0, 0);
             
            window.QCodeDecoder().decodeFromImage(canvas.toDataURL(), function(err, res){
            //document.body.appendChild(document.createTextNode(res||err))
    
              if(res===undefined){
    
              }
              else{
                clearInterval(CamInterval);

                let barcode = res.split('=');
               
                  if(barcode[1].length>13)
                  {
                  setInputValue(barcode[1]);
                  }
              
              //  document.body.appendChild(document.createTextNode(res||err))
    
              }
              });
             } catch (error) {
               //  alert(error);
             }

        
       },200)

      

             HasSnap=false;
           
            });
        }
    }, 500);

  }




    useEffect(() => {
    
      if(Cashhash && !InputValue){
      window.Webcam.set({
        width: 200,
        height: 200,
        dest_width: 200, // device capture size
      dest_height: 200,
      crop_width: 200, // final cropped size
      crop_height: 200,
		image_format: 'jpeg',
		jpeg_quality: 90
		});
		window.Webcam.attach("#my_camera");
  }


      InputValue  ?
      axios({
        method: 'get',
        url: `${Api}/api/mget/GetUser?barcode=${InputValue}`,
        headers: {
                'Authorization': "Bearer " + location.state.tokendata,
                 "Content-Type": "application/json" },
      }).then(res => {
        setDataCash(res.data)
        navigate('/BarcodeCashDetails',{state:{ Tokens:location.state.tokendata ,BarcodeDetails:res.data , Username:location.state.Username  , userId:location.state.userId , Password:location.state.Password ,credit:(NewCredit ==""?location.state.credit : NewCredit) }})
      }
      ).catch(err => err.response.status === 401 ? navigate('/') : err.response.status === 400 ? toast.warning("The process is currently unavailable.") : "")
      :
      console.log("")
    })

  return (
    <div className="CreditInContainer">
       {
              InfoCash ? 
              <div>
              <div className='headerArea'>
                 <div className='headerText'><div className='HeaderImageTextArea'>Cashier</div></div>
               </div>
               <div className='SuccessArea'>
                      <div className='SuccesText'>Success</div>
                      <div className='CreditText'>Credit :{parseInt(SelectValueCash.split(":")[2]) - parseInt(SelectNumberCash)} </div>
                      <button onClick={TurnHomeCash}  className='buttonLightBlueSuccessCredit' >OK</button>
               </div>
             </div>
             :
             <div>
    <div className='headerArea'>
    <div className='headerText'><div className='HeaderImageTextArea'><button className='ImageButton' onClick={() => (setCreditNew(""),   navigate('/Home',{state:{  credit:(NewCredit==""? location.state.credit:NewCredit) ,token:location.state.tokendata , from:DataCash, UserNameInfo:location.state.Username , Password:location.state.Password , userId:location.state.userId }}))}><img width={25} height={20} className='imgStyleBack' src='./BackLogo.png'></img></button><span className='TextHeaderStyle'>Scan</span></div><img onClick={threeDotCashOut} width={31} height={31} className='imgStyleBack' src='./Threedot.png'></img></div>
    </div>
    {
       dotCashOut ?
       <div className='modalLogin'>
         <div className='UsernameAreaModal'>{location.state.Username}</div>
         <Link to={'/'} className='logoutButtonModal'>LogOut</Link>
       </div>
       :
       ""
     }
    <div className='AltMenu'>
       <div onClick={hashTest} className={`${Cashhash ? "CreditAltActive" : "CreditInAlt"}`}>USE HASH</div>
       <div onClick={getUsersCash} className={`${Cashhash ? "CreditInAlt" : "CreditAltActive"}`}>USE VOUCHER</div>
    </div>
    {
     Cashhash ? 
          <div>
              <div className="ImageArea">

                <div className='' onClick={onsnap} onTouchStart={onsnap} id="my_camera"></div>
                  <img id="das" width={200} height={200} alt="" src=""/>
              </div>
              <div className='loginArea'>
               <div className='AmountArea'>
            <div className='AmountText'>Amount</div>
               <div className='InputEffect' type="text" value={InputValue} onChange={ChangeInputValue}  name="amount"  required="required" >{InputValue}</div>
          </div>
       </div>
     </div>
          :
          <div className='container'>
        <div className='formStyle' > 
             <select className='SelectStyle' onChange={handleChangeCash} >
             <option  value="">Select Voucher ...</option>
             {Object.values(SelectDataCash).map((value, index) => {
        return (
          <option  key={index} value={value.user_Name + ":" + value.user_Id +":" +value.credit}>{value.user_Name}</option>
        );
      })}
            </select>

      </div>
       {
        SelectValueCash == ""  ?  "" : 
        <div className='BarcodeTextArea'>
           <div className='BarcodeDetailsText'><span className='BarcodeTextHeader'>Username :</span> {SelectValueCash.split(":")[0]}</div>
           <div className='BarcodeDetailsText'><span className='BarcodeTextHeader'>Credit :</span> {SelectValueCash.split(":")[2]}</div>
       </div>
                 
       }
    
          <div className='CreditAmountArea'>
            <div className='AmountText'>Credits Amounts:</div>
            <div className='InputEffect' type="text" value={SelectNumberCash}  name="amount" onChange={SelectNumberInputCash}  required="required">{SelectNumberCash}</div>
          </div>
          <div className='CreditNumberArea'>
          <div className='RowAreaCredit'>
              <button id='1' onClick={SelectNumberFuncCash}>1</button>
              <button id='2' onClick={SelectNumberFuncCash}>2</button>
              <button id='3' onClick={SelectNumberFuncCash}>3</button>
            </div>
            <div className='RowAreaCredit'>
              <button id='4' onClick={SelectNumberFuncCash}>4</button>
              <button id='5' onClick={SelectNumberFuncCash}>5</button>
              <button id='6' onClick={SelectNumberFuncCash}>6</button>
            </div>
            <div className='RowAreaCredit'>
              <button id='7' onClick={SelectNumberFuncCash}>7</button>
              <button id='8' onClick={SelectNumberFuncCash}>8</button>
              <button id='9' onClick={SelectNumberFuncCash}>9</button>
            </div>
            <div className='RowAreaCredit'>
              <button id='00' onClick={SelectNumberFuncCash}>00</button>
              <button id='0' onClick={SelectNumberFuncCash}>0</button>
              <button onClick={SelectNumberFuncCashDelete} >DEL</button>
            </div>
            <div  className='buttonAreaVoucher'>
               <button onClick={CreditUpdateCash} className='buttonLightBlueCredit' >VOUCHER CREDITS OUT</button>
               <ToastContainer />
            </div>
          </div>
    </div>
    }
   </div>
       }

 </div>
  )
}
