
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    Routes,
    Navigate
  } from "react-router-dom";
  import { useParams ,useNavigate } from 'react-router-dom';
import { useEffect, useState  ,useContext } from 'react';
import axios from 'axios';
import { useLocation } from 'react-router-dom'
import  Context  from './Context';

export default function Credits() {
       const navigate = useNavigate();
       const location = useLocation();
       const [dot, setDot] = useState(false);
       const [CreditNew, setCreditNew] = useState("");
       const {Api} = useContext(Context)


       window.didchange = function(uid , suid){
             if(location.state.userId == uid ) {
              axios({
                     method: 'post',
                     url: `${Api}/api/mget/logon?username=${location.state.UserNameInfo}&Password=${location.state.Password}`,
                     headers: {
                            'Authorization': "Bearer " + location.state.token,
                            "Access-Control-Allow-Origin": "localhost:3000" ,
                             "Content-Type": "application/json" },
                   }).then(res => {
                     if(res.data.activ == "1" && res.data.userExist == "1") {
                        setCreditNew(res.data.credit)
                     }
                     else if(res.data.activ == "0"){
                              navigate('/')
                     }
                   }
                   )
             }
           }

       function threeDot(){
          setDot(!dot)
       }

       const NavigateTest=()=>{
              navigate('/NewVoucher',{state:{tokendata:location.state.token, Username:location.state.UserNameInfo , credit:(CreditNew==""?location.state.credit:CreditNew) , Password:location.state.Password , userId:location.state.userId }})
       }

       const NavigateCredit=()=>{
              navigate('/CreditIn',{state:{tokendata:location.state.token, credit:(CreditNew==""?location.state.credit:CreditNew) , Username:location.state.UserNameInfo , userId:location.state.userId ,Password:location.state.Password  }})
       }
       const NavigateCashOut=()=>{
              navigate('/CashOut',{state:{tokendata:location.state.token, Username:location.state.UserNameInfo , credit:(CreditNew==""?location.state.credit:CreditNew) ,userId:location.state.userId ,Password:location.state.Password }})
       }
       const NavigateLastVoucher=()=>{
              navigate('/LastVoucher',{state:{tokendata:location.state.token, Username:location.state.UserNameInfo , credit:(CreditNew==""?location.state.credit:CreditNew) ,userId:location.state.userId ,Password:location.state.Password  }})
       }
       const NavigateProfile=()=>{
              navigate('/Profile',{state:{tokendata:location.state.token, Username:location.state.UserNameInfo , credit:(CreditNew==""?location.state.credit:CreditNew) ,userId:location.state.userId ,Password:location.state.Password  }})
       }

  return (
    <div className="container">
    <div className='headerArea'>
    <div className='headerText'><div className='HeaderImageTextArea'><span className='CreditsHeaderText'>Credits</span> <span className='CreditTextHome'>{CreditNew == "" ?location.state.credit : CreditNew}</span></div><img onClick={threeDot} width={31} height={31} className='imgStyleBack' src='./Threedot.png'></img></div>
    </div>
     {
       dot ?
       <div className='modalLogin'>
       <div  className='UsernameAreaModal'>{location.state.UserNameInfo}</div>
       <Link to={'/'} className='logoutButtonModal'>LogOut</Link>
     </div>
       :
       ""
     }
    <div className="ImageArea">
        <img src='./Cashier.png' width={160} height={160}></img>
    </div>
    <div className='loginArea'>
        <div  className='buttonArea'>
              <button onClick={NavigateTest} className='buttonGreen'>CREATE NEW VOUCHER</button>
        </div>
        <div  className='buttonArea'>
               <button onClick={NavigateCredit} className='buttonOrange'>CREDIT IN</button>
        </div>
        <div  className='buttonArea'>
               <button  onClick={NavigateCashOut}  className='buttonLightBlue'>CASH OUT</button>
        </div>
        <div  className='buttonArea'>
               <button onClick={NavigateLastVoucher} className='buttonpurple' >LAST VOUCHER</button>
        </div>
        <div  className='buttonArea'>
               <button onClick={NavigateProfile} className='buttonYellow'  >PROFILE</button>
        </div>
    </div>
 </div>
  )
}
