

import React, { useState ,useEffect , useContext} from 'react';
import {Link, Routes, Route, useNavigate} from 'react-router-dom';
import { useLocation } from 'react-router-dom'
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import  Context  from './Context';


export default function Voucher()  {
  const location = useLocation();
  const navigate = useNavigate();
    const [Amount, setAmount] = useState("");
    const [AmountNumber, setAmountNumber] = useState(null);
    const [dotVoucher, setDotVoucher] = useState(false);
    const [putVoucher, set] = useState(false);
    const [Success, setSuccess] = useState(false);
    const [Unsuccess, setUnSuccess] = useState(false);
    const [Check, setCheck] = useState(false);
    const [Data , setData] = useState()
    const [NewCredit , setCreditNew] = useState("")
    const [DataVoc , setDataVoc] = useState("")
    const {Api} = useContext(Context)


    function threeDotVoucher(){
      setDotVoucher(!dotVoucher)
    }

    window.didchange = function(uid ){
      if(location.state.userId == uid) {
       axios({
              method: 'post',
              url: `${Api}/api/mget/logon?username=${location.state.Username}&Password=${location.state.Password}`,
              headers: {
                     'Authorization': "Bearer " + location.state.tokendata,
                     "Access-Control-Allow-Origin": "localhost:3000" ,
                      "Content-Type": "application/json" },
            }).then(res => {
              if(res.data.activ == "1" && res.data.userExist == "1") {
                 setCreditNew(res.data.credit)
              }
              else if(res.data.activ == "0"){
                       navigate('/')
              }
            }
            )
      }
}


   function NewVoucher(){
    Amount.length> 0  && Amount <= (NewCredit== "" ? location.state.credit : NewCredit) ?
    axios({
      method: 'put',
      url: `${Api}/api/mget/CreateUser?Credit=${Amount}`,
      headers: {
              'Authorization': "Bearer " + location.state.tokendata,
              "Access-Control-Allow-Origin": "localhost:3000" ,
               "Content-Type": "application/json" },
    }).then(res => {
      setData(res.data)
      setSuccess(true)
      javascript:window.lee.PrintCode(res.data.user_Name+"!"+res.data.password+"!"+res.data.loginBarcode+"!"+res.data.siteName);   
    }
    
    ).catch(err => err.response.status === 401 ? navigate('/') : err.response.status === 400 ? toast.warning("The process is currently unavailable.") : "")
    :
    Amount === ""?
    toast.warning("Amount cannot be empty")
    :
    Amount >= (NewCredit== "" ? location.state.credit : NewCredit) ?
    toast.warning(`You don't have ${Amount} credit`)
    :
    console.log()
   }

   const CheckOption=(event)=>{
    setCheck(Amount+event.currentTarget.id)
   }

   const AmountCount=(event)=>{
    Amount == 0 ?
    setAmount(event.currentTarget.id)
    :
    setAmount(Amount+event.currentTarget.id)
   }

   function deleteAmountCount() {
    setAmount("")
   }

   function TurnVoucherSuccess() {
    setSuccess(false)
    setUnSuccess(false)
    setAmount("")
    setCreditNew("")
    axios({
      method: 'post',
      url: `${Api}/api/mget/logon?username=${location.state.Username}&Password=${location.state.Password}`,
      headers: {
              'Authorization': "Bearer " + location.state.tokendata,
               "Content-Type": "application/json" },
    }).then(res => {
      navigate('/Home',{state:{ credit:res.data.credit ,token:location.state.tokendata , from:res.data , UserNameInfo:location.state.Username ,userId :location.state.userId , Password:location.state.Password}})
    }
    ).catch()
   }
   function TurnVoucherUnSuccess() {
    setSuccess(false)
    setUnSuccess(false)
    setAmount("")
   }

    const onChangeHandlerAmount = event => {
      setAmount(event.target.value);
   };

  return (
    <div className='container'>
      {
        Success ? 
        <div>
         <div className='headerArea'>
            <div className='headerText'><div className='HeaderImageTextArea'>Cashier</div></div>
          </div>
          <div className='SuccessArea'>
                 <div className='SuccesText'>Success</div>
                 <div className='CreditText'>Credit : {Amount}</div>
                 <button className='buttonLightBlueSuccessCredit' onClick={TurnVoucherSuccess}>OK</button>
                 <ToastContainer />
          </div>
        </div>
        :
        Unsuccess ?
        <div>
        <div className='headerArea'>
           <div className='headerText'><div className='HeaderImageTextArea'>Cashier</div></div>
         </div>
         <div className='SuccessArea'>
                <div className='UnSuccesText'>Unsuccess</div>
                <div className='CreditText'>You don't have {Amount} credits</div>
                <button className='buttonRedUnSuccessCredit' onClick={TurnVoucherUnSuccess}>OK</button>
                <ToastContainer />
         </div>
       </div>
        :

    <div>
          <div className='headerArea'>
          <div className='headerText'><div className='HeaderImageTextArea'><button className='ImageButton' onClick={() => (setCreditNew("") , navigate('/Home',{state:{ credit:(NewCredit == "" ? location.state.credit : NewCredit) ,token:location.state.tokendata , from:DataVoc , UserNameInfo:location.state.Username ,userId :location.state.userId , Password:location.state.Password}}))}><img width={25} height={20} className='imgStyleBack' src='./BackLogo.png'></img></button><span className='TextHeaderStyle'>Voucher In </span></div><img onClick={threeDotVoucher} width={31} height={31} className='imgStyleBack' src='./Threedot.png'></img></div>
          </div>
          {
       dotVoucher ?
       <div className='modalLogin'>
         <div className='UsernameAreaModal'>{location.state.Username}</div>
         <Link to={'/'} className='logoutButtonModal'>LogOut</Link>
       </div>
       :
       ""
     }
          <div className='AmountArea'>
            <div className='AmountText'>Amount</div>
            <input type="number" value={Amount} onChange={onChangeHandlerAmount}  name="amount"  required="required" />
          </div>
          <div className='NumberArea'>
            <div className='RowArea'>
              <button id='1' onClick={AmountCount}>1</button>
              <button id='2' onClick={AmountCount}>2</button>
              <button id='3' onClick={AmountCount}>3</button>
            </div>
            <div className='RowArea'>
              <button id='4' onClick={AmountCount}>4</button>
              <button id='5' onClick={AmountCount}>5</button>
              <button id='6' onClick={AmountCount}>6</button>
            </div>
            <div className='RowArea'>
              <button id='7' onClick={AmountCount}>7</button>
              <button id='8' onClick={AmountCount}>8</button>
              <button id='9' onClick={AmountCount}>9</button>
            </div>
            <div className='RowArea'>
              <button id='00' onClick={AmountCount}>00</button>
              <button id='0' onClick={AmountCount}>0</button>
              <button onClick={deleteAmountCount} >DEL</button>
            </div>
            <div  className='buttonAreaVoucher'>
               <button onClick={NewVoucher} className='buttonVoucher' >VOUCHER IN</button>
               <ToastContainer />
            </div>
          </div>
          </div>
            }
    </div>
  )
}
